<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">暢遊沖繩必備的景點套票，精選沖繩多個人氣設施與美食店家，一週內可以任選三個喜歡的設施，出示電子票券QR code就可以輕鬆入場體驗。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同設施只可以使用一次，相同設施不可再入場<br>
          ●票劵使用效期為購買日起<span>90天內</span><br>
           EX:<span>7/1</span>購買票券⇒票期:<span>7/1~9/28</span><br>
          ●票券有效期限: 使用第一個設施後，<span>7日內</span>須使用完畢<br>
          EX:<span>7/1</span>開始使用，<span>有效期限至7/7</span><br>
          ●有效期間內可任<span>選3項設施</span><br>
          ●注意: 單次購買複數票劵時，當其中一張票劵開始用，所有票劵也將同步開通 (<span>使用效期7天</span>)<br>
          ●票券開通後，7天的使用效期無法超過票券<span>90天</span>的有效期間。<br>
           EX:票券期限<span>7/1~9/28</span>，若<span>9/27</span>開始使用，其使用效期為<span>9/27~9/28</span><br>
          ●無使用完畢不能兌現<br>
          ●請事先於以下連結確認各設施注意事項・營業時間・公休日情報
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兌換地點' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='官方網站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfokinawa/tc/havefun_title_tc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfokinawa/tc/oguri_kimono_salon_TC.jpg'),
          title: ['Oguri Kimono Salon和服體驗 1000日元優惠券 (大人限定)'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒900-0014 沖繩縣那霸市松尾2-3-13 2F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['http://oguri-kimono.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。 (僅適用於高中生以上) '] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfokinawa/tc/chibai_TC.jpg'),
          title: ['燒肉HORUMON 赤白 1000日元優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒900-0033 沖繩縣那霸市久米2丁目20-21八木大樓 1樓'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.instagram.com/yakinikuhorumon_akashiro/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfokinawa/tc/SZG_TC.jpg'),
          title: ['沖繩美麗海水族館入場券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒905-0206 沖繩縣國頭郡本部町字石川1421-5 （海風MARKET本部店）'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://churaumi.okinawa/tc/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。','<br>※台風等不可抗力因素導致公共交通暫停運行情況也將暫停營業。'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfokinawa/tc/LQC_TC.jpg'),
          title: ['沖繩琉球村入場券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '沖繩縣國頭郡恩納村山田1130'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.ryukyumura.co.jp/official/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。','<br>※台風等不可抗力因素導致公共交通暫停運行情況也將暫停營業。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfokinawa/tc/YQD_TC.jpg'),
          title: ['沖繩文化王國入場券套票（玉泉洞・王國村・毒蛇博物公園）'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒901-0616　沖繩縣南城市玉城前川1336番地'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.gyokusendo.co.jp/okinawaworld/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。','<br>※台風等不可抗力因素導致公共交通暫停運行情況也將暫停營業。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfokinawa/tc/zwy_TC.jpg'),
          title: ['東南植物樂園入場券【日間票】'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '沖繩縣沖繩市知花2146'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.southeast-botanical.jp/tw/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。','<br>※台風等不可抗力因素導致公共交通暫停運行情況也將暫停營業。'] },
                { cid: 2, text: ['可入園時間: 9:00~17:00 ','<br>※晚間入園請現場另外購票'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfokinawa/tc/kl_TC.jpg'),
          title: ['DINO恐龍公園 山原亞熱帶之森入場券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '沖繩縣名護市中山1024-1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.okashigoten.co.jp/zh-TW/subtropical/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。','<br>※台風等不可抗力因素導致公共交通暫停運行情況也將暫停營業。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfokinawa/tc/bl_TC.jpg'),
          title: ['沖繩名護菠蘿園入場券（含乘坐菠蘿號）'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒905-0005沖縄縣名護市為又1195'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.nagopine.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。','<br>※台風等不可抗力因素導致公共交通暫停運行情況也將暫停營業。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfokinawa/tc/Neopark_TC.jpg'),
          title: ['Neopark 沖繩 入場券+沖繩輕鐵乘車套餐'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒905-0012沖繩縣名護市名護4607-41'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.neopark.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。','<br>※小火車運行時間：10:00 am～17:00 pm（每半小時一班，最後班次17:00）'] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfokinawa/tc/sllr_TC.jpg'),
          title: ['首里琉染珊瑚染體驗1000日圓折價券 (需要預約)'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒903-0825 沖縄縣那覇市首里山川町 1-54'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content:
                [
                { cid: 1, text: ['https://www.shuri-ryusen.com/']},
                { cid: 2, text: ['預約網站:'],noColor:true},
                { cid: 3, text: ['ahttps://reserve.shuri-ryusen.com/step1']},
                { cid: 4, text: ['<span style="color:red">※預約時，付款方法請選擇到店付款的選項</span>'] ,noColor:true },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['全預約制營業，請事先預約。沒有事先預約的話，可能會有休業的情況。請務必確認後再使用。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['體驗場次：9:30 / 11:00 / 12:30 / 14:00 / 15:30','<br>※體驗所需時間約50分鐘，請在體驗時間前15分鐘到場。'] },
                { cid: 4, text: ['有可能會因為人數額滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfokinawa/tc/haidi_TC.jpg'),
          title: ['冲绳那霸水中观光船Orca　1000日圓折價券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '沖縄縣那覇市通堂町２−１(那霸碼頭 旅客等候區 WEST MARINE)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content:
                [
                { cid: 1, text: ['https://tw.westmarine.co.jp/top.php']},
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。'] },
                { cid: 2, text: ['體驗所需時間約50～60分鐘，'] },
                { cid: 3, text: ['若當天乘客比較多的情況，安全起見可能為採用分批進入觀察室觀賞。'] },
                { cid: 4, text: ['遊覽船會進行不定期的維修護理，維修護理期間停航。另外，由於天氣及海況不可預測（例如：風浪太大船身不穩、海中能見度不佳等），可能當天抵達現場後才通知不適合登船，屆時可選擇放棄或者改期參加。'] },
                { cid: 5, text: ['有可能會因為人數額滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfokinawa/tc/yuyi_TC.jpg'),
          title: ['漫步沖繩那霸美櫻和服/浴衣體驗1000日圓折價券 (指定進店時間：11:00)'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒900-0015沖繩縣那霸市久茂地1-6-1ART大樓2-A'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content:
                [
                { cid: 1, text: ['https://www.churasakura.okinawa/#']},
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。'] },
                { cid: 2, text: ['指定進店時間：11:00'] },
                { cid: 3, text: ['如果入店時客人比較多的情況，需要稍等片刻，敬請諒解。'] },
                { cid: 4, text: ['請於當天18:00以前返還，遲到的話會自動轉為次日歸還，次日返還時需付加每人1100日元，並且出租當天無法取回自己的隨身物品，敬請注意。'] },
                { cid: 5, text: ['歸還時，租賃品如果有不可修復的污漬（油漆等）、破損（香菸烙燒、縫製部分以外的破損、破洞等）將收取5000日元作為賠償金，請當地支付。'] },
                { cid: 6, text: ['當天建議穿件方便換裝的打底吊帶或緊身衣衫。'] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfokinawa/tc/heniu_TC.jpg'),
          title: ['沖繩海景餐廳和牛烤肉牛武士1000日圓折價券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '中頭郡北谷町字美浜34−3 DEPOT CENTRAL 6F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content:
                [
                { cid: 1, text: ['https://www.gyu-samurai.com/']},
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。 (僅適用於以下套餐)',
                '<br>A套餐內容（根據季節稍有變動)<br>前菜2種拼盤、泡菜拼盤、上等牛舌、嚴選上等牛里脊、嚴選上等牛五花、縣產阿古豬五花、特選西冷牛排、內臟三種拼盤、海帶雞蛋湯、米飯、甜點、無酒精飲料一杯',
                '<br>B套餐內容（根據季節稍有變動)<br>前菜3種拼盤、本日色拉、泡菜拼盤、上等牛舌、極上霜降牛里脊、極上霜降牛五花、特選厚切菲力、縣產阿古豬五花、海鮮3種拼盤、內臟三種拼盤、海帶雞蛋湯、米飯、甜點、無酒精飲料一杯'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['餐廳可能出現客满而無法使用票券的情況，敬請諒解。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFOkinawa&l=tc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okinawa-1-week-free-pass/tc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>